<template>
    <div class="sidepanel-wrapper">
        <div class="sidepanel-backdrop" @click="$emit('close')"></div>
        <div class="sidepanel">
            <div class="sidepanel-header">
                <div class="sidepanel-title">{{ title }}</div>
                <button class="btn btn-clear" @click="$emit('close')"></button>
            </div>

            <div class="sidepanel-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title'],
}
</script>
