<template>
    <PrintFile v-if="isFinished && !missingFiles.length" :files="files"></PrintFile>

    <div v-else class="print-loader">
        <div v-if="tooManyFiles" class="text-error">{{ $t('reports.too_many_files') }}</div>
        <div v-else>
            <div class="mb-2">{{ $t('reports.loading_files', { current: files.length, max: ids.length }) }}</div>
            <progress class="progress my-4" :value="files.length" :max="ids.length"></progress>

            <div v-if="isFinished && missingFiles.length">
                <div class="text-error">{{ $t('reports.some_files_could_not_be_loaded') }}</div>
                <ul>
                    <li>
                        <a href="#" @click="missingFiles = []">{{ $t('reports.create_report_with_missing_files') }}</a>
                    </li>
                    <li>
                        <a href="#" @click="reload()">{{ $t('reports.reload_page_and_try_again') }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import PrintFile from '~/components/print/PrintFile.vue'

export default {
    components: { PrintFile },

    data() {
        return {
            files: [],
            missingFiles: [],
            isFinished: false,
            ids: this.$route.params.id.split(',')
        }
    },

    computed: {
        tooManyFiles() {
            return this.ids.length > 100
        }
    },

    async mounted() {
        if (this.tooManyFiles) return

        for (const id of this.ids) {
            try {
                const { data } = await this.$axios.get(`files/${id}`)

                data.uploads = data.uploads.filter(upload => upload.include_in_share)
                data.form_submits = data.form_submits.filter(submit => submit.shared)

                this.files.push(data)
            } catch {
                this.missingFiles.push(id)
            }
        }

        this.isFinished = true

        if (this.files.length) {
            document.title = this.files[0].scan_code
        }
    },

    methods: {
        reload() {
            window.location.reload()
        }
    }
}
</script>
