<template>
    <input v-if="step.mask" v-maska="{ mask: step.mask, tokens }" v-model="answer.value" :masked="true"
        :placeholder="step.placeholder" :type="step.type" :id="'step-' + step.id" class="form-input input-lg"
        @change="$emit('change')">

    <FileFieldsModel v-else-if="step.field" :id="'step-' + step.id" v-model="answer.value" :field="step.field"
        :required="false" class="input-lg" />

    <input v-else :placeholder="step.placeholder" :id="'step-' + step.id" class="form-input input-lg"
        v-model="answer.value" @change="$emit('change')" />
</template>

<script>
import { vMaska } from 'maska/vue'
import FileFieldsModel from '~/components/files/FileFieldsModel.vue'

export default {
    props: ['step', 'answer'],
    components: { FileFieldsModel },
    directives: { maska: vMaska },

    data: () => ({
        tokens: {
            A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        }
    }),
}
</script>
