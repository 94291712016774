<template>
    <WatchForUnsavedChanges ref="watch" v-model="workflows" v-slot="{ hasChanges }">
        <Spinner v-if="loading"></Spinner>
        <Modal v-else :title="$t('devices.change_order_for_workflows_in_app')"
            @close="$router.push({ name: 'workflows' })">
            <div class="box" v-if="!workflows.length">{{ $t('main.no_data_found') }}</div>

            <Container v-else @drop="onDrop" lock-axis="y" drag-handle-selector=".dnd-item">
                <Draggable v-for="(workflow, index) in workflows" :key="workflow.id">
                    <div class="dnd-item c-hand">
                        <div class="handler py-2" :data-position="index + 1 + '.'">
                            <i class="fas fa-grip-vertical"></i>
                        </div>
                        <div class="mr-auto">{{ workflow.name }}</div>
                    </div>
                </Draggable>
            </Container>

            <template #footer>
                <SaveButton @click="save()" :disabled="!hasChanges" />
            </template>
        </Modal>
    </WatchForUnsavedChanges>
</template>

<script>
import { Container, Draggable } from 'vue3-smooth-dnd'

export default {
    emits: ['refresh'],
    components: { Container, Draggable },

    data() {
        return {
            workflows: [],
            loading: true,
            id: this.$route.params.id,
        }
    },

    async mounted() {
        const { data: paginator } = await this.$axios.get('workflows', {
            params: { sort: 'order', by: 'asc', limit: 200 },
        })

        this.workflows = paginator.data
        this.loading = false
    },

    methods: {
        async save() {
            await this.$axios.post('workflows_order', this.workflows)

            this.$refs.watch?.init()
            this.$router.push({ name: 'workflows' })
            this.$toast(this.$root.$t('devices.order_for_app_applied'))
        },

        onDrop(dropResult) {
            this.workflows = this.drop(this.workflows, dropResult)
        },

        drop(arr, dropResult) {
            const { removedIndex, addedIndex, payload } = dropResult

            if (removedIndex === null && addedIndex === null) return arr

            const result = [...arr]
            let itemToAdd = payload

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd)
            }

            return result
        },
    },
}
</script>
