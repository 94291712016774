<script setup>
import InfoDialog from './InfoDialog.vue'
import DangerDialog from './DangerDialog.vue'
import ConfirmDialog from './ConfirmDialog.vue'

import { props } from '~/plugins/dialog.js'

const { open, options, handleConfirm, handleCancel } = props

const components = {
    'InfoDialog': InfoDialog,
    'DangerDialog': DangerDialog,
    'ConfirmDialog': ConfirmDialog,
}
</script>

<template>
    <component v-if="open" :is="components[options.component]" :key="options.title" :options="options"
        @confirm="handleConfirm" @cancel="handleCancel" class="modal-dialog" />
</template>