<template>
    <input v-if="field.type === 'text'" v-model="value" class="form-input" maxlength="255" />

    <input v-else-if="field.type === 'date'" type="date" v-model="value" class="form-input" />

    <label v-else-if="field.type === 'checkbox'" class="form-checkbox">
        <input type="checkbox" v-model="value" :true-value="1" :false-value="0" />
        <i class="form-icon"></i>
    </label>

    <input v-else-if="field.type === 'number'" type="number" v-model="value" class="form-input" max="2147483647" />

    <select v-else-if="field.type === 'select'" v-model="value" class="form-select" :required="required">
        <option v-for="value in field.properties.select" :key="value">
            {{ value }}
        </option>
    </select>

    <select v-else-if="field.type === 'geo_fencing'" v-model="value" class="form-select" required>
        <option v-for="(fence, index) in field.properties.geo_fencing" :value="fence.name" :key="index">
            {{ fence.name }}
        </option>
    </select>

    <input v-else v-model="value" class="form-input" />
</template>

<script>
export default {
    props: {
        modelValue: [String, Number],
        field: Object,
        autoFocus: Boolean,
        required: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },

    mounted() {
        if (this.autoFocus) {
            this.$el.focus()
        }
    },
}
</script>
