<template>
    <WatchForUnsavedChanges ref="watch" v-model="list" v-slot="{ hasChanges }">
        <Spinner v-if="id && !list.id"></Spinner>
        <SidePanel v-else :title="`${id ? $t('main.edit') : $t('context.create_distribution_lists')}`"
            @close="$router.push({ name: 'settings.contact_lists' })">
            <form @submit.prevent="saveContactList()">
                <div class="form-group">
                    <Errors :errors="errors" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="name">{{ $t('distribution_lists.name') }}</label>
                    <input id="name" class="form-input" v-model="list.name" :placeholder="$t('distribution_lists.name')"
                        required />
                </div>

                <div class="form-group">
                    <label class="form-label" for="customer_id">{{ $t('distribution_lists.customer_id') }}</label>
                    <input id="customer_id" class="form-input" v-model="list.customer_id"
                        :placeholder="$t('distribution_lists.customer_id')" required />
                </div>

                <div class="form-group">
                    <label class="form-label">{{ $t('share.recipients') }}</label>
                    <div class="flex-space mb-1" v-for="(_, i) in list.recipients">
                        <div class="input-group" style="flex: 1">
                            <input type="email" class="form-input " v-model="list.recipients[i].email"
                                :placeholder="$t('users.email')" required />

                            <input class="form-input" v-model="list.recipients[i].label"
                                :placeholder="$t('distribution_lists.label')" />
                        </div>

                        <a href="#" class="btn btn-sm btn-clear ml-2" @click.prevent="removeRecipient(i)"></a>
                    </div>

                    <button type="button" class="btn mt-1" @click="addRecipient()">
                        <i class="far fa-plus mr-2"></i>
                        <span>{{ $t('distribution_lists.add_recipient') }}</span>
                    </button>
                </div>

                <div class="form-group">
                    <label class="form-label" for="language">{{ $t('main.language') }}</label>
                    <select v-model="list.language" id="language" class="form-select" required>
                        <option v-for="(lang, key) in locales" :value="key">{{ lang }}</option>
                    </select>
                </div>

                <div class="flex-space">
                    <button class="btn btn-primary" type="submit" :disabled="!hasChanges">
                        {{ id ? $t('main.save') : $t('main.create') }}
                    </button>
                    <button v-if="id" type="button" class="btn btn-error" @click="deleteList()">
                        {{ $t('main.delete') }}
                    </button>
                </div>
            </form>
        </SidePanel>
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'

export default {
    emits: ['refresh'],
    components: { SidePanel },

    data() {
        return {
            errors: null,
            id: this.$route.params.id,
            locales: window.config.locales,

            list: {
                name: '',
                language: 'en',
                recipients: [{ email: '', label: '' }],
            },
        }
    },

    async mounted() {
        if (this.id) await this.fetchData()
        this.$refs.watch?.init()
    },

    methods: {
        async fetchData() {
            const { data: list } = await this.$axios.get(`distribution_lists/${this.id}`)

            this.list = list
        },

        async saveContactList() {
            this.errors = null

            try {
                this.id ?
                    await this.$axios.patch(`distribution_lists/${this.id}`, this.list) :
                    await this.$axios.post('distribution_lists', this.list)
            } catch ({ response }) {
                this.errors = response
            }

            this.$toast(this.$root.$t(this.id ? 'i.update' : 'i.create', { i: this.list.name }))

            this.$refs.watch?.init()
            this.$router.push({ name: 'settings.contact_lists' })
            this.$emit('refresh')
        },

        deleteList() {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`distribution_lists/${this.list.id}`)
                
                this.$refs.watch?.init()
                this.$router.push({ name: 'settings.contact_lists' })
                this.$toast(this.$root.$t('i.delete', { i: this.list.name }))
                this.$emit('refresh')
            })
        },

        addRecipient() {
            this.list.recipients.push({ email: '', label: '' })
        },

        removeRecipient(index) {
            if (this.list.recipients.length > 1) {
                this.list.recipients.splice(index, 1)
            }
        },
    },
}
</script>
