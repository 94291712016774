<template>
    <SidePanel :title="$t('context.create_api_tokens')" @close="$router.push({ name: 'settings.api_tokens' })">
        <form v-if="$store.state.user.properties.use_api" @submit.prevent="createApiToken()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="system">{{ $t('api_tokens.system') }}</label>
                <input class="form-input" v-model="system" id="system" :placeholder="$t('api_tokens.system')" />
            </div>

            <div class="form-group">
                <label class="form-label" for="dp-input-expires">{{ $t('main.expiry') }}</label>
                <DatePicker v-model="expires" uid="expires" :placeholder="$t('main.no_expiry')"></DatePicker>
            </div>

            <div class="flex-space">
                <button type="submit" class="btn btn-primary">{{ $t('main.create') }}</button>
                <button type="button" class="btn" @click="$router.push({ name: 'settings.api_tokens' })">
                    <span>{{ $t('main.cancel') }}</span>
                </button>
            </div>
        </form>
        <NeedsPremiumLicense v-else :text="$t('license_limit.api_tokens')"
            :limit-text="$t('license_limit.premium_feature')"></NeedsPremiumLicense>
    </SidePanel>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            system: '',
            expires: '',

            errors: null,
        }
    },

    methods: {
        createApiToken() {
            this.errors = null

            this.$axios
                .post('api_tokens', {
                    system: this.system,
                    expires: this.expires,
                })
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.api_tokens' })
                })
                .catch(({ response }) => {
                    this.errors = response
                })
        },
    },
}
</script>
