<template>
    <WatchForUnsavedChanges ref="watch" v-model="location" v-slot="{ hasChanges }">
        <Spinner v-if="!location.id"></Spinner>
        <SidePanel v-else :title="$t('main.location')" @close="$router.push({ name: 'settings.teams' })">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <fieldset :disabled="!canEdit">
                <div class="form-group">
                    <label class="form-label" for="name">{{ $t('main.name') }}</label>
                    <input v-model="location.location" class="form-input" id="name" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="language">{{ $t('main.language') }}</label>
                    <select v-model="location.lang" class="form-select" id="language">
                        <option v-for="(lang, key) in locales" :value="key">{{ lang }}</option>
                    </select>
                </div>

                <details class="accordion">
                    <summary class="accordion-header" @click.once="loadDamageTypes()">
                        <span>{{ $t('locations.damage_types') }}</span>
                        <i class="fas fa-chevron-right fa-fw ml-2"></i>
                    </summary>
                    <div class="accordion-body">
                        <div class="menu menu-nav">
                            <div class="menu-item flex-space" v-for="item in location.damage_types" :key="item.id">
                                <label class="form-switch" :for="'dmg-types-' + item.id">
                                    <span>{{ textFromType(item) }}</span>
                                </label>
                                <label class="form-switch">
                                    <input :id="'dmg-types-' + item.id" v-model="item.enabled" type="checkbox" />
                                    <i class="form-icon"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="divider"></div>

                <details class="accordion">
                    <summary class="accordion-header" @click.once="loadDocumentTypes()">
                        <span>{{ $t('locations.document_types') }}</span>
                        <i class="fas fa-chevron-right fa-fw ml-2"></i>
                    </summary>
                    <div class="accordion-body">
                        <div class="menu menu-nav">
                            <div class="menu-item flex-space" v-for="item in location.document_types" :key="item.id">
                                <label class="form-switch" :for="'doc-types-' + item.id">
                                    <span>{{ textFromType(item) }}</span>
                                </label>
                                <label class="form-switch">
                                    <input :id="'doc-types-' + item.id" v-model="item.enabled" type="checkbox" />
                                    <i class="form-icon"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="divider"></div>

                <details class="accordion">
                    <summary class="accordion-header">
                        <span>{{ $t('locations.default_device_settings') }}</span>
                        <i class="fas fa-chevron-right fa-fw ml-2"></i>
                    </summary>
                    <div class="accordion-body">
                        <div class="mx-2 p-2">
                            <div class="mb-4">
                                <div class="flex-space">
                                    <label class="form-switch" for="default_delete_after_upload">
                                        <span>{{ $t('devices.delete_after_upload') }}</span>
                                    </label>
                                    <label class="form-switch">
                                        <input id="default_delete_after_upload" type="checkbox"
                                            v-model="location.default_delete_after_upload" :true-value="false"
                                            :false-value="true" />
                                        <i class="form-icon"></i>
                                    </label>
                                </div>

                                <div class="flex-space">
                                    <label class="form-switch" for="default_allow_upload_without_wifi">
                                        <span>{{ $t('devices.allow_upload_without_wifi') }}</span>
                                    </label>
                                    <label class="form-switch">
                                        <input id="default_allow_upload_without_wifi" type="checkbox"
                                            v-model="location.default_allow_upload_without_wifi" />
                                        <i class="form-icon"></i>
                                    </label>
                                </div>

                                <div class="flex-space" :class="{ ghost: location.default_allow_upload_without_wifi }">
                                    <label class="form-switch" for="default_force_damage_upload">
                                        <span>{{ $t('devices.force_damage_upload') }}</span>
                                    </label>
                                    <label class="form-switch">
                                        <input id="default_force_damage_upload" type="checkbox"
                                            v-model="location.default_force_damage_upload" />
                                        <i class="form-icon"></i>
                                    </label>
                                </div>
                            </div>

                            <div class="mb-2" v-if="location.id">
                                <button class="btn mr-2" @click="$refs.widgetBuilder.showModal()">
                                    <i class="fas fa-fw fa-mobile-alt mr-2"></i>
                                    <span>{{ $t('devices.manage_widgets') }}</span>
                                </button>

                                <button class="btn" @click="$refs.regexBuilder.showModal()">
                                    <i class="fas fa-fw fa-exclamation-triangle mr-2"></i>
                                    <span>{{ $t('regex.reference_warning') }}</span>
                                </button>
                            </div>

                            <div v-show="location.advanced_settings">
                                <div class="flex-space">
                                    <label class="form-switch" for="apply_settings_to_all_devices">
                                        <span>{{ $t('locations.apply_to_devices') }}</span>
                                    </label>
                                    <label class="form-switch">
                                        <input id="apply_settings_to_all_devices" v-model="applySettingsToDevices"
                                            @change="applySettingsToAllDevices()" type="checkbox" />
                                        <i class="form-icon"></i>
                                    </label>
                                </div>

                                <div v-if="applySettingsToDevices" style="overflow-y: auto; max-height: 500px">
                                    <div v-for="device in location.devices" :key="device.id">
                                        <label class="form-checkbox checkbox-sm">
                                            <input v-model="device.enabled" type="checkbox" />
                                            <i class="form-icon"></i>
                                            <span>{{ device.nickname }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="divider"></div>

                <details class="accordion mb-4">
                    <summary class="accordion-header" @click.once="loadData()">
                        <span>{{ $t('locations.default_share_settings') }}</span>
                        <i class="fas fa-chevron-right fa-fw ml-2"></i>
                    </summary>
                    <div class="accordion-body">
                        <div class="mx-2 p-2">
                            <div class="form-group">
                                <label class="form-label" for="mask">{{ $t('share.sub') }}</label>
                                <textarea id="mask" v-model="location.share_subject_mask" class="form-input"></textarea>
                            </div>

                            <div class="flex-space">
                                <label class="form-switch" for="share_allow_zip_download">
                                    <span>{{ $t('share.allow_zip_download') }}</span>
                                </label>
                                <label class="form-switch">
                                    <input id="share_allow_zip_download" v-model="location.share_allow_zip_download"
                                        type="checkbox" :true-value="1" />
                                    <i class="form-icon"></i>
                                </label>
                            </div>

                            <div class="flex-space">
                                <label class="form-switch" for="share_allow_pdf_download">
                                    <span>{{ $t('share.allow_pdf_download') }}</span>
                                </label>
                                <label class="form-switch">
                                    <input id="share_allow_pdf_download" v-model="location.share_allow_pdf_download"
                                        type="checkbox" :true-value="1" />
                                    <i class="form-icon"></i>
                                </label>
                            </div>

                            <select v-if="location.share_allow_pdf_download"
                                v-model="location.share_allow_pdf_download_layout" class="form-select">
                                <option :value="null" disabled hidden>{{ $t('reports.layout') }}</option>
                                <option v-for="t in templates" :key="t.id" :value="t.code">{{ t.name }}</option>
                            </select>

                            <div class="flex-space">
                                <label class="form-switch" for="auto_share">
                                    <span>{{ $t('locations.auto_share') }}</span>
                                </label>
                                <label class="form-switch">
                                    <input id="auto_share" v-model="location.auto_share" type="checkbox"
                                        :true-value="1" />
                                    <i class="form-icon"></i>
                                </label>
                            </div>

                            <select v-if="location.auto_share" v-model="location.auto_share_dl_id" class="form-select">
                                <option :value="null" disabled hidden>{{ $t('locations.auto_share_distribution_list') }}
                                </option>
                                <option v-for="list in contactLists" :key="list.id" :value="list.id">{{ list.name }}
                                </option>
                            </select>

                            <div class="form-group mt-4">
                                <label for="share_customer_reference_field_id" class="form-label">
                                    {{ $t('share.customer_reference') }}
                                </label>
                                <select class="form-select" id="share_customer_reference_field_id"
                                    v-model="location.share_customer_reference_field_id">
                                    <option :value="null">{{ $t('share.no_reference') }}</option>
                                    <option :key="field.id" :value="field.id" v-for="field in fields">
                                        {{ field.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="flex-space">
                    <SaveButton @click="save()" :disabled="!hasChanges" />
                    <button class="btn btn-error" @click="deleteLocation()" :disabled="!canDelete">
                        {{ $t('main.delete') }}
                    </button>
                </div>
            </fieldset>
        </SidePanel>

        <WidgetBuilder ref="widgetBuilder" :widgets="location.default_widgets" @save="saveDefaultWidgets" />
        <RegexBuilder ref="regexBuilder" :regex="location.default_scancode_regex"
            :custom="location.default_scancode_custom_regex" @save="saveScancodeRegex" />
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import RegexBuilder from '~/components/builders/RegexBuilder.vue'
import WidgetBuilder from '~/components/builders/WidgetBuilder.vue'

export default {
    emits: ['refresh'],

    components: {
        SidePanel,
        WidgetBuilder,
        RegexBuilder,
    },

    data() {
        return {
            errors: null,
            dataLoaded: false,

            fields: [],
            location: {},
            templates: [],
            contactLists: [],

            id: this.$route.params.id,
            locales: window.config.locales,

            applySettingsToDevices: false,

            canDelete: false,
            canEdit: this.$store.getters.can('Update locations'),
        }
    },

    async mounted() {
        const { data: location } = await this.$axios.get(`locations/${this.id}`)

        location.devices.forEach(e => { e.enabled = false })

        this.location = location
        this.canDelete = !location.users.length && !location.devices.length

        if (this.$store.state.user.role === 'Manager') {
            this.canEdit = this.$store.state.user.location_id === location.id
        }

        if (this.location.advanced_settings === false) {
            this.applySettingsToDevices = true
            this.applySettingsToAllDevices()
        }
    },

    methods: {
        async loadData() {
            if (this.dataLoaded) return

            await this.loadReportTemplates()
            await this.loadFieldsForCustomerReference()
            await this.loadContactLists()

            this.dataLoaded = true
        },

        async loadReportTemplates() {
            const { data: templates } = await this.$axios.get('report_templates')

            this.templates = templates
        },

        async loadFieldsForCustomerReference() {
            const { data: paginator } = await this.$axios.get('fields', {
                params: { limit: 200, type: ['text', 'number'] },
            })

            this.fields = paginator.data
        },

        async loadContactLists() {
            const { data: paginator } = await this.$axios.get('distribution_lists', {
                params: { limit: 1000 },
            })

            this.contactLists = paginator.data
        },

        saveDefaultWidgets(widgets) {
            this.location.default_widgets = widgets
            this.save()
        },

        saveScancodeRegex(regex, custom) {
            this.location.default_scancode_regex = regex
            this.location.default_scancode_custom_regex = custom
            this.save()
        },

        async save() {
            this.errors = null

            try {
                await this.$axios.patch(`locations/${this.id}`, this.location)

                this.$emit('refresh')
                this.$toast(this.$root.$t('i.update', { i: this.location.location }))
                this.$refs.watch?.init()
            } catch ({ response }) {
                this.errors = response
            }
        },

        deleteLocation() {
            if (!this.canDelete) return

            this.$dialog.danger(async () => {
                await this.$axios.delete(`locations/${this.id}`)

                this.$refs.watch?.init()
                this.$router.push({ name: 'settings.teams' })
                this.$toast(this.$root.$t('i.delete', { i: this.location.location }))
                this.$emit('refresh')
            })
        },

        loadDamageTypes() {
            this.$axios.get(`damage_types/${this.id}`).then(({ data }) => {
                this.location['damage_types'] = data
                this.$refs.watch?.init()
            })
        },

        loadDocumentTypes() {
            this.$axios.get(`document_types/${this.id}`).then(({ data }) => {
                this.location['document_types'] = data
                this.$refs.watch?.init()
            })
        },

        applySettingsToAllDevices() {
            this.location.devices.forEach(device => (device.enabled = this.applySettingsToDevices))
        },

        textFromType(type) {
            const locationLang = this.location.lang
            const typeLanguage = type.languages.find(l => l.lang === locationLang) || type.languages.find(l => l.lang === 'en') || {}

            return typeLanguage.description
        },
    },
}
</script>
