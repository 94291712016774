<template>
    <div>
        <div class="timeline" :class="[viewType, filter]" v-viewer="options">
            <div v-for="run in ordered(items)" :key="run.id" :class="{ 'workflow-run': run.client_key }">
                <div class="workflow-run-header flex-space" v-if="run.client_key">
                    <h5 class="workflow-title">{{ run.workflow.name }}</h5>
                    <div class="workflow-progress flex-space">
                        <figure v-if="run.completed_at" class="avatar avatar-xs">
                            <i class="fas fa-check"></i>
                        </figure>
                        <h5>{{ $t('timeline.progress', { current: run.steps_done, total: getTotalSteps(run) }) }}</h5>
                        <Dropdown class="dropdown-right">
                            <div class="menu-item">
                                <a href="#" @click.prevent="moveWorkflowRun(run)">
                                    <span>{{ $t('files.move_to_another_file') }}</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a href="#" class="text-error" @click.prevent="deleteWorkflowRun(run)">
                                    <span>{{ $t('main.delete') }}</span>
                                </a>
                            </div>
                        </Dropdown>
                    </div>
                </div>

                <template v-for="step in ordered(run.workflow_run_steps)" :key="step.id">
                    <TimelineItemFields v-if="step.entity_type === 'fields'" :step="step" />
                    <TimelineItemSkipped v-else-if="step.status === 'skipped'" :date="step.submit_date_time" />
                    <template v-else v-for="entity in ordered(step.entities)" :key="entity.upload_type + entity.id">
                        <component :is="TYPE_TO_COMPONENT[entity.upload_type]" :item="entity"
                            :hideUploader="hideUploader" :class="entity.upload_type"
                            :data-no-access="editableUntil < entity.created_at ? $t('table.trial_expired') : null"
                            @edit="onEdit" @share="onShare" @delete="onDelete">
                        </component>
                    </template>
                </template>
            </div>
        </div>

        <div v-if="!items.length" class="text-center">
            <img height="300" src="/images/snappy/idle.png" alt />
            <p class="text-gray">{{ $t('main.no_data_found') }}</p>

            <div v-if="$route.name === 'files.show'" class="create-snap">
                <router-link class="btn" :to="{ name: 'files.upload' }">
                    <i class="fas fa-file-arrow-up"></i>
                    <span class="ml-2">{{ $t('files.upload') }}</span>
                </router-link>
            </div>
        </div>

        <TimelineItemUploadEdit v-if="itemEdit" :item="itemEdit" @update="$emit('refreshTimeline')"
            @close="itemEdit = null">
        </TimelineItemUploadEdit>

        <Modal v-if="showOnMap" @close="$router.push({ query: $route.query })" class="modal-larger">
            <MapsView :snap_id="showOnMap" :uploads="uploads" style="height: 50vh"></MapsView>
        </Modal>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { mapGetters } from 'vuex'
import { directive as viewer } from 'v-viewer'

import MapsView from '~/components/MapsViewAsync.vue'
import TimelineItemForm from './TimelineItemForm.vue'
import TimelineItemFields from './TimelineItemFields.vue'
import TimelineItemUpload from './TimelineItemUpload.vue'
import TimelineItemUploadEdit from './TimelineItemUploadEdit.vue'
import TimelineItemAttachment from './TimelineItemAttachment.vue'
import TimelineItemSkipped from './TimelineItemSkipped.vue'
import { DialogError } from '~/plugins/dialog.js'

export default {
    components: {
        MapsView,
        TimelineItemForm,
        TimelineItemUpload,
        TimelineItemUploadEdit,
        TimelineItemAttachment,
        TimelineItemFields,
        TimelineItemSkipped,
    },

    props: {
        fields: Array,
        uploads: Array,
        form_submits: Array,
        workflow_runs: Array,

        filter: String,
        sortDesc: Boolean,
        viewType: String,
        hideUploader: Boolean,
    },

    directives: { viewer: viewer() },

    data() {
        return {
            items: [],
            itemEdit: null,
            showOnMap: null,

            TYPE_TO_COMPONENT: {
                'form': 'TimelineItemForm',
                'snap': 'TimelineItemUpload',
                'seal': 'TimelineItemUpload',
                'video': 'TimelineItemUpload',
                'document': 'TimelineItemUpload',
                'barcodeTag': 'TimelineItemUpload',
                'containerTag': 'TimelineItemUpload',
                'serial-shooter': 'TimelineItemUpload',
                'multi_page_document': 'TimelineItemUpload',
                'licensePlateScanner': 'TimelineItemUpload',
                'attachment': 'TimelineItemAttachment',
            },

            options: {
                button: false,
                transition: false,
                url: 'data-source',
                viewed: this.onView,
            },
        }
    },

    computed: mapGetters({ editableUntil: 'filesEditableUntil' }),

    created() {
        for (let i = 0; i < this.workflow_runs.length; i++) {
            const run = this.workflow_runs[i]

            run.steps_done = 0

            for (let j = 0; j < run.workflow_run_steps.length; j++) {
                const step = run.workflow_run_steps[j]
                step.entities = []

                for (let k = 0; k < step.entity_ids.length; k++) {
                    const id = step.entity_ids[k]
                    const entity = this[step.entity_type].find(u => u.id === id)

                    if (entity) {
                        step.entities.push(entity)
                    }
                }

                if (step.entities.length || step.status === 'skipped') {
                    run.steps_done++
                }
            }
        }

        this.items = this.sortWorkflowRuns(this.workflow_runs)
    },

    watch: {
        '$route.hash': {
            immediate: true,
            handler(hash) {
                const splits = hash.split('#map-')
                this.showOnMap = splits[1] ? splits[1] : null
            },
        },
    },

    methods: {
        sortWorkflowRuns(runs) {
            runs.forEach(run => {
                run.workflow_run_steps.forEach(step => {
                    step.entities.sort((a, b) => new Date(a.scan_date_time) - new Date(b.scan_date_time))
                })

                run.workflow_run_steps.sort((a, b) => new Date(a.submit_date_time) - new Date(b.submit_date_time))
            })

            return runs.sort((a, b) => new Date(a.submit_date_time) - new Date(b.submit_date_time))
        },

        ordered(list) {
            if (this.sortDesc) {
                return [...list].reverse()
            }

            return list
        },

        onView(e) {
            const viewer = e.target.viewer
            const thumbElement = viewer.images[viewer.index]

            // Only apply rotation hack if thumbnail is loaded
            if (thumbElement.getAttribute('lazy') !== 'loaded') return

            const origIsPortrait = this.isPortrait(viewer.imageData)
            const thumbIsPortrait = this.isPortrait(thumbElement)

            if (origIsPortrait !== thumbIsPortrait) {
                viewer.rotate(90)
            }
        },

        isPortrait(img) {
            const w = img.naturalWidth || img.width
            const h = img.naturalHeight || img.height

            return h > w
        },

        onShare(item) {
            if (item.upload_type === 'form') {
                item.shared = !item.shared
                this.$axios.patch(`submits/${item.id}`, item)
            } else {
                item.include_in_share = !item.include_in_share
                this.$axios.patch(`uploads/${item.id}`, item)
            }
        },

        onEdit(item) {
            this.itemEdit = Object.assign({}, item)
        },

        onDelete(item) {
            this.$dialog.danger(async () => {
                const endpoint = item.upload_type === 'form' ? 'submits' : 'uploads'
                await this.$axios.delete(`${endpoint}/${item.id}`)

                this.$toast(this.$root.$t('i.delete', { i: item.upload_type }))
                this.$emit('refresh')
            })
        },

        moveWorkflowRun(run) {
            this.$dialog.confirm(async (reference) => {
                try {
                    // A little counter-intuitive, but we clear the storage
                    // to ensure that the search results are not cached
                    this.$axios.storage.clear()

                    const { data: files } = await this.$axios.get('search', { params: { reference, open: true } })
                    const { data } = await this.$axios.post(`files/${run.file_id}/move_wf/${run.id}/${files[0].id}`)

                    this.$router.push({ name: 'files.show', params: { id: data.id } })
                } catch {
                    throw new DialogError(this.$root.$t('files.move_wf_run_could_not_find_an_open_file'))
                }
            }, {
                input: '',
                title: this.$t('files.move_to_another_file'),
                text: this.$t('files.move_wf_run_to_another_file', { workflow: run.workflow.name }),
            })
        },

        deleteWorkflowRun(run) {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`files/${run.file_id}/delete_wf/${run.id}`)
                this.$toast(this.$root.$t('i.delete', { i: run.workflow.name }))
                this.$emit('refresh')
            })
        },

        getTotalSteps(run) {
            return Math.max(run.steps_done, run.workflow.steps.length)
        },
    },
}
</script>
