const intercomInit = (app_id) => {
    if (!app_id) return

    const script = document.createElement('script')
    script.setAttribute('src', `https://widget.intercom.io/widget/${app_id}`)

    script.async = true
    script.onload = () => {
        window.Intercom && window.Intercom('boot', { app_id })
    }

    document.head.appendChild(script)
}

export default intercomInit

