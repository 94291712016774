export default {
    beforeMount(el, binding) {
        let clickedOutside = false

        const handleMouseDown = event => {
            // Do not consider clicks on scrollbar
            if ((window.innerWidth - event.clientX) < 15) return

            // Check if the click is outside the element
            clickedOutside = !(el === event.target || el.contains(event.target))
        }

        const handleMouseUp = event => {
            // Check if the click released is still outside the element
            if (clickedOutside && !(el === event.target || el.contains(event.target))) {
                binding.value(event)
            }

            clickedOutside = false
        }

        const handleKeyUp = event => {
            // Consider Escape key to be outside click
            if (event.key === 'Escape') {
                binding.value(event)
            }
        }

        document.addEventListener('keyup', handleKeyUp)
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('mousedown', handleMouseDown)

        el._cleanup = () => {
            document.removeEventListener('keyup', handleKeyUp)
            document.removeEventListener('mouseup', handleMouseUp)
            document.removeEventListener('mousedown', handleMouseDown)
        }
    },

    unmounted(el) {
        el._cleanup()
    },
}